import {roleNames} from "./roleNames";

const routeNames = [
	{	
		acceptedRoles : [roleNames.unauthorized],
		routes :[
			{
				route : '/',
				name : 'В начало'
			},
			{
				route : '/login',
				name : 'Авторизация'
			},
			{
				route : '/registration',
				name : 'Регистрация'
			},
      {
				route : '/ticketWindowList',
				name : 'Кассы'
			},
		]
	},
	{	
		acceptedRoles : [roleNames.customer],
		routes :[
			{
				route : '/invoiceForm',
				name : 'Оформить заявку'
			},
			{
				route : '/ticketWindowList',
				name : 'Кассы'
			},
			// {
			// 	route : '/invoiceList',
			// 	name : 'Список заявок'
			// },
			{
				route : '/personalAccount',
				name : 'Личный кабинет'
			},
		]
	},
	{	
		acceptedRoles : [roleNames.accountant],
		routes :[
			{
				route : '/userInfo/',
				name : 'Поиск по пользователям'
			},
			{
				route : '/search/invoice/',
				name : 'Поиск по заявкам'
			},
			{
				route : '/userInfo/extract/',
				name : 'Реестр'
			},
		]
	},
	{	
		acceptedRoles : [roleNames.moderator, roleNames.admin],
		routes :[
			{
				route : '/createTravelDocument',
				name : 'Добавление ПД'
			},
			{
				route : '/travelDocumentList',
				name : 'Список ПД'
			},
		]
	},
	{	
		acceptedRoles : [roleNames.admin],
		routes :[
			{
				route : '/user/status/',
				name : 'Просмотр пользователя'
			},
		]
	},
];
export default routeNames;