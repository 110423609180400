import "./Login.css";
import React, {useState}  from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Box} from "@mui/material";
import {Row} from "react-bootstrap";
import {setPasswordChangeRequester} from "../../../features/passwordChange/passwordChangeSlice";
import {authSuccess} from "../../../features/auth/authSlice";
import {LoadingButton} from "@mui/lab";
import {userApi} from "../../../api/userApi";
import {alertStates, useAlert} from "../../../states/useAlert";
import {FormInput, formInputProps, AlertChoise, AlertDialog} from '../../../Components';
import { onActivationRequesterChanged } from "../../../features/activation/activationSlice";

const dialogDefaultState = {
	state : false,
	dialogTitleText : "",
	dialogMainText : "",
	onCancel : ()=>{},
	onOk : ()=>{}
};

const Login = ()=> {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [loginData, setLoginData] = useState({
		email: "",
		password: ""
	});
	const [trigger, result] = userApi.useLazyLogInQuery();
	const {alertStatus, setAlertStatus} = useAlert();
	const [dialogState, setDialogState] = useState(dialogDefaultState);
	
	const onLogin = (token : string) => dispatch(authSuccess(token));
	const setPassChangeRequester = (email : string) => dispatch(setPasswordChangeRequester(email));
	const setActivationRequester = (email : string) => dispatch(onActivationRequesterChanged(email));

	const handleChange = (e : React.ChangeEvent<HTMLInputElement>)=>{
		setLoginData({...loginData, [e.target.name] : e.target.value});
	};

	const submitForm = (e : React.FormEvent<HTMLFormElement>)=>{
		e.preventDefault();
		trigger(loginData).then(res => {
			if(res.isSuccess){
				onLogin(res.data);
			}else if (res.isError){
				const {status, message} = res.error;
				setAlertStatus(true, alertStates.errorAlert, message);
				if(status === 406){
					setDialogState({
						state : true,
						dialogTitleText : "Активация аккаунта",
						dialogMainText : "Для входа в систему, необходимо потвердить электронную почту. Желаете продолжить?",
						onCancel : resetAccountActivation,
						onOk : acceptAccountActivation
					});
				}
			}
		});
	};
    
	const acceptAccountActivation = () =>{
		resetForm();
		setActivationRequester(loginData.email);
		setDialogState(dialogDefaultState);
		navigate("/accountActivation");
	};

	const resetAccountActivation = () =>{
		setAlertStatus(true, alertStates.errorAlert, "Без подтверждения email-адреса невозможна работа в системе");
		setDialogState(dialogDefaultState);
	};

	function passRestoreClick(){
		if(!loginData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(loginData.email)){
			setAlertStatus(true, alertStates.errorAlert, "Для изменения пароля введите email, на который зарегистрирован аккаунт, в поле ввода");
			resetForm();
			return;
		}
		setDialogState({
			state : true,
			dialogTitleText : "Восстановление пароля",
			dialogMainText : "Для восстановления пароля вам будет отправлен одноразовый код на электронную почту. Для восстановление пароля нажмите продолжить",
			onCancel : ()=>setDialogState(dialogDefaultState),
			onOk : ()=>{
				let {email} = loginData;
				setPassChangeRequester(email);
				setDialogState(dialogDefaultState);
				navigate("/passwordChangeCodeInput", { state: { isFromLogin : true } });
			}
		});
	}

	const resetForm = (clearSession = true)=>{
		setLoginData({password : "", email : ""});
		if (clearSession)
			sessionStorage.clear();
	};

	return (
		<div className="login_container">
			<h2 className='log_success_header'>
				Вход в систему
			</h2>
			<p className='login_text'> 
				Для входа в систему введите email и пароль
			</p>
			<form 
				id = "login-form" 
				onSubmit={submitForm} 
				onReset={e=>resetForm()}
				>
				<Box 
					sx={{
						width:"50%", 
						margin:"auto"
					}}
					>
					<FormInput 	
						label={"email"}
						inputProps={ 
							formInputProps({
								type : "text",
								name : "email",
								onChange :handleChange,
								value : loginData.email,
								placeholder : "example@minsktrans.by"
							})
						}
						/>			
					<FormInput 	
						label={"Пароль"} 
						inputProps={ 
							formInputProps({
								type : "password",
								name : "password",
								onChange : handleChange,
								value : loginData.password,
								placeholder : "********",
								minLength : 8,
								autoComplete : 'current-password'
							})
						}
						/>
				</Box>
				<Box 
					sx={{
						width:"50%", 
						margin:"auto", 
						marginTop:"0.5rem"
					}}
					>
					<AlertChoise {...alertStatus}/>
				</Box>
				<Box
					className = "text-center" 
					sx={{
							textAlign:"center", 
							marginTop : "2rem"
					}}
					>
					<LoadingButton 	
						className="submitLog"
						color = "success" 
						variant="contained"
						type ="submit"
						loading = {result.isFetching}
						>
						<span>
							{!result.isFetching ? "Войти" : "Выполняется вход..."}
						</span>
					</LoadingButton>
				</Box>
				<Row style={{paddingTop:"0.5rem"}}>    
					<Box  className='passwordRestoreButtonBox'> 
						<button 
							type= 'button' 
							className='passwordRestoreButton' 
							onClick={()=>navigate('/registration')}
							>
							Зарегистрироваться
						</button>
					</Box>
				</Row>
				<Row style={{paddingTop:"0"}}>         
					<Box className='passwordRestoreButtonBox'> 
						<button 
							type= 'button' 
							className='passwordRestoreButton' 
							onClick={passRestoreClick}
							>
							Я не помню пароль
						</button>
					</Box>
				</Row>
			</form>
            <AlertDialog 	{...dialogState}/>
		</div>
	);
};
export default Login;