import React, {useState}  from "react";
import {Typography, Box, Grid, TextField, Button, ToggleButtonGroup, ToggleButton, Select, MenuItem, Snackbar, Alert, AlertColor, SnackbarCloseReason, SelectChangeEvent} from "@mui/material";
import styled from "styled-components";
import moment from "moment";
import {useEffect} from "react";
import config from "../../../config/config";
import PasswordChangeDialog from "./PasswordChangeDialog";
import {userApi} from "../../../api";
import {LoadingButton} from "@mui/lab";
import { UserRole } from "../../../app/types/userTypes";

const LabelItem = styled(Typography)(() => ({
	backgroundColor: '#fff',
	textAlign: 'left',
	fontSize:"large",
	padding:"0.5vh 2vh",
	fontFamily:"sans-serif"
  }));

const Item = styled(Typography)(() => ({
	backgroundColor: '#fff',
	textAlign: 'left',
	fontSize:"large",
	padding:"0.5vh 0vh",
	
	fontWeight : "bold",
  }));

const defaultUserDataState = {
	registrationNumber: "",
	email: "",
	roleName : "",
	roleLabel : '',
	statusName : '',
	registrationDate : '',
	dataFromServer : false //|| true
};
const searchParams = {
	email : {
		label : "Email", 
		placeholder : "example@gmail.com",
		inputProps : {
			type : 'email'
		}
	},
	registrationNumber:{
		label : "Унп пользователя", 
		placeholder : "111111111",
		inputProps : {
			maxLength : 9,
		}
	}
};
const DataItem = ({label, item, children} : React.PropsWithChildren<{item? : string, label? : string}>) =>{
	return (
		<Grid 	
			item 
			xs={12} 
			container
			alignItems={'center'}
			>
			<Grid 
				item xs={6} 
				key={1}
				>
				<LabelItem>
					{label}
				</LabelItem>
			</Grid>
			<Grid 
				item 
				xs={6}  
				key={2}
				>
				{children ?? 
					<Item>
						{(item === '' ? '-' : item)}
					</Item>}
			</Grid>
		</Grid>
	);
};



const UsersAdminPanel = ()=> {
	const [searchValue, setSearchValue] = useState('');
	const [searchType, setSearchType] = useState ('email');
	const [userData, setUserData] = useState(defaultUserDataState);
	const [userRoles, setUserRoles] = useState([] as UserRole[]);
	const [userStatuses, setUserStatuses] = useState([] as string[]);
	const statusesQuery = userApi.useGetUserStatusesAndRolesQuery({}, {skip : userRoles.length !== 0, pollingInterval : 5000});
	const [searchUser, searchResult] = userApi.useLazyAdminUserSearchQuery();
	const [updateUserData, updateResult] = userApi.useAdminUpdateUserMutation();
	const [blockUser, blockResult] = userApi.useAdminBlockUserMutation();
	const [open, setOpen] = React.useState(false);
	const [snackbar, setSnackbar] = useState({type : 'success', text : ''});
	const [blockStatus, setBlockStatus] = useState(false);
	const [passChangeDialogState, setPassChangeDialogState] = useState(false);

	useEffect (()=>{
		if(statusesQuery.isSuccess){
			const {roles, statuses} = statusesQuery.data;
			setUserRoles(roles);
			setUserStatuses(statuses);
		}else if (statusesQuery.isError){
			console.log(statusesQuery.error);
			setUserRoles([]);
			setUserStatuses([]);
			setTimeout(()=> setUserRoles([]), config.againQueryTimeout);
			setSnackbar({type : 'error',text : statusesQuery.error.message});
			setOpen(true);
		}
	},[statusesQuery]);
	
	const submitSearchForm = (e : React.FormEvent<HTMLFormElement> | null, searchVal = searchType, searchStr = searchValue) =>{
		e?.preventDefault();
		searchUser({[searchVal] : searchStr}).unwrap()
		.then(user =>{
			const 	registrationDate = moment(user.registrationDate).format('LL');
			setBlockStatus(user.statusName === 'Заблокирован');
			setUserData( {...user, registrationDate, dataFromServer : true});
		})
		.catch(error=>{
			console.log(error);
			setSnackbar({type : 'error',text : error.message});
			setOpen(true);
		});
	};

	const submitEditForm = (e : React.FormEvent<HTMLFormElement>) =>{
		e.preventDefault();
		updateUserData({...userData, oldEmail : searchResult?.data?.email})
		.then(response =>{
			setSearchType('email');
			setSearchValue(userData.email);
			submitSearchForm(null, 'email', userData.email);
			setSnackbar({type : 'success', text : (response as {data :string}).data});
			setOpen(true);
		})
		.catch(error=>{
			console.log(error);
			setSnackbar({type : 'error',text : error.message});
			setOpen(true);
		});
	};

	const blockUserButtonClick = () =>{
    console.log(searchResult?.data?.email)
		blockUser({email : searchResult?.data?.email, block : !blockStatus})
    .unwrap()
    .then(message =>{
			setUserData({...userData, statusName : blockStatus ? 'Активирован' : 'Заблокирован'});
			setBlockStatus(!blockStatus);
			setSnackbar({type : 'success',text : message});
			setOpen(true);
		})
		.catch(error => {
			console.log(error);
			setSnackbar({type : 'error',text : error.message});
			setOpen(true);
		});
	};

	const changePasswordClick = () => {
		setPassChangeDialogState(true);
	};

	const handleUserDataChange = (e : React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		setUserData({...userData, [e.target.name] : e.target.value});
	};
	const handleSelectChange = (e : SelectChangeEvent<string> ) => {
		e.preventDefault();
		setUserData({...userData, [e.target.name] : e.target.value});
	};

	const handleClose = (event : Event | React.SyntheticEvent<any, Event>, reason : SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	return (
		<>
			<Typography 
				variant="h4" 
				align="center" 
				sx={{marginBottom:"2vh"}}
				>
					Поиск пользователя:
			</Typography>
			<Box 	
				component="form"
				id = 'adminUserPanelForm'
				onSubmit={submitSearchForm}
				>
				<Grid 	
					container
					columnSpacing={2}
					justifyContent={'space-evenly'}
					alignItems={'center'}
					>
					<Grid 	
						item 
						xs = {8}
						>
						<TextField 	
							type="search"
							required
							label = {searchParams[searchType as keyof typeof searchParams].label}
							placeholder = {searchParams[searchType as keyof typeof searchParams].placeholder}
							inputProps = {searchParams[searchType as keyof typeof searchParams].inputProps}
							value={searchValue}
							onChange={(e) => {setSearchValue(e.target.value);setUserData(defaultUserDataState);}}
							fullWidth 
							/>
					</Grid>
					<Grid 	
						item 
						xs = {2}
						>
						<ToggleButtonGroup
							color="primary"
							value={searchType}
							
							exclusive
							onChange={(e, newValue)=>{
								setSearchType(newValue ?? 'registrationNumber'); 
								setSearchValue(''); 
								setUserData(defaultUserDataState);
							}}
							aria-label="SearchType"
							sx = {{
								justifyContent:'center', 
								height:"100%"
							}}
							fullWidth
							>
							<ToggleButton 
								value="registrationNumber" 
								sx={{fontWeight:'bold'}}
								>
								Логин
							</ToggleButton>
							<ToggleButton 	
								value="email" 
								sx={{fontWeight:'bold'}}
								>
								Email
							</ToggleButton>
						</ToggleButtonGroup>
					</Grid>

					<Grid 	item 
							xs = {2}
							>
						<LoadingButton 
								type="submit"
								variant="outlined"
								sx = {{fontSize:"large", width:"100%", height:"100%"}}
								loading = {searchResult.isFetching}
								>
							Поиск
						</LoadingButton>
					</Grid>
				</Grid>
			</Box>
			<Box 	
				component="form"
				id = 'adminUserDataForm'
				onSubmit={submitEditForm}
				>
				<Grid 	
					container
					spacing={1}
					sx = {{marginTop:'2vh'}}
					>
					<DataItem 	
						label = {'Логин'}
						item = {userData.registrationNumber}
						>
						<TextField 	
							value = {userData.registrationNumber}
							name = 'registrationNumber'
							onChange={handleUserDataChange}
							type="text"
							required
							label = {'Логин'}
							placeholder = {'111222333'}
							inputProps = {{
								maxLength : 9, 
							}}
							fullWidth
							disabled = {!userData.dataFromServer}
							/>
					</DataItem>
					<DataItem 	
						label = {'Email'}
						item = {userData.email}
						>
						<TextField 	
							value = {userData.email}
							name = 'email'
							onChange={handleUserDataChange}
							type="email"
							required
							label = {'Email'}
							placeholder = {'example@gmail.com'}
							inputProps = {{
								maxLength : 50, 
							}}
							fullWidth 
							disabled = {!userData.dataFromServer}
							/>
					</DataItem>
					<DataItem 	label = {'Роль пользователя'}
								item = {userData.roleLabel}
								>
						<Select 
							value={userData.roleName}
							name = 'roleName'
							onChange={handleSelectChange}
							required
							fullWidth
							disabled = {!userData.dataFromServer}
							>
							{
								userRoles?.map(role => 
										(
										<MenuItem 	
											value = {role.roleName}
											key = {role.roleName}
											>
											{role.roleLabel}
										</MenuItem>
									))
							}
						</Select>
					</DataItem>
					<DataItem 	
						label = {'Статус аккаунта'}
						item = {userData.roleLabel}
						>
						<Select 
						value={userData.statusName}
						name = 'statusName'
						onChange={handleSelectChange}
						required
						fullWidth
						disabled = {!userData.dataFromServer}
						>
						{
							userStatuses?.map(status => 
									(
									<MenuItem 	
										value = {status}
										key = {status}
										>
										{status}
									</MenuItem>
								))
						}
						</Select>
					</DataItem>		
					<DataItem 	
						label = {'Дата регистрации'}
						item = {userData.registrationDate}
						/>
					<Grid 	
						item 
						container
						xs = {12}
						spacing={2}
						sx={{marginTop:"2vh"}}
						justifyContent={'space-evenly'}
						>
						<Grid item xs = {3}>
							<LoadingButton 
								type='button'
								onClick={blockUserButtonClick}
								color=  {blockStatus ? 'success': 'error'}
								variant='contained'
								sx = {{fontSize:"large",height:"100%"}}
								fullWidth
								disabled = {!userData.dataFromServer}
								loading = {blockResult.isLoading}
								>
								{blockStatus ? 'Разблокировать пользователя': 'Заблокировать пользователя'}
							</LoadingButton>
						</Grid>		
						<Grid item xs = {3}>
							<Button 
								type='button'
								onClick={changePasswordClick}
								color=  {'warning'}
								variant='contained'
								sx = {{fontSize:"large",height:"100%"}}
								fullWidth
								disabled = {!userData.dataFromServer}
								>
								Изменить пароль
							</Button>
						</Grid>
						<Grid item xs = {3}>
							<LoadingButton 
								type="submit"
								variant='contained'
								sx = {{fontSize:"large",height:"100%"}}
								fullWidth
								disabled = {!userData.dataFromServer}
								loading = {updateResult.isLoading}
								>
								Обновить данные пользователя 
							</LoadingButton>
						</Grid>		
					</Grid>
				</Grid>
				<Snackbar 
					open={open} 
					autoHideDuration={3000} 
					onClose={handleClose}
					>
					<Alert 
						severity={snackbar.type as AlertColor} 
						sx={{width: '100%'}}
						>
						{snackbar.text}
					</Alert>
				</Snackbar>
				<PasswordChangeDialog 	
					open = {passChangeDialogState} 
					setOpen = {setPassChangeDialogState}
					setSnackbar={(type, message) => {setSnackbar({type,text : message}); setOpen(true);}}
					email={searchResult?.data?.email ?? ""} 
        />
			</Box>
		</>
	);
};
export default UsersAdminPanel;