import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import SessionService from "../../services/sessionService";
const codeSendSessionItemName =  "isActivationCodeSend";
const activationRequesterSessionItemName = "activationCodeRequester";
const codeSendService = new SessionService<boolean>(codeSendSessionItemName, false);
const activationRequesterService = new SessionService<string>(activationRequesterSessionItemName, null);

type ActivationState  = {
	isCodeSend : boolean | null,
	email : string | null
};

const initialState : ActivationState  = {
	isCodeSend : codeSendService.getItem(),
	email : activationRequesterService.getItem(),
} ;

export const activationSlice = createSlice({
	name : "activation",
	initialState,
	reducers : {
		onCodeSendChanged : (state, action : PayloadAction<boolean>) =>{
			const value = action.payload;
			if(value !== true && value  !== false){
				return;
			}
			state.isCodeSend = value ;
			codeSendService.saveItem(value);
		},
		onActivationRequesterChanged : (state, action : PayloadAction<string | null>) =>{
			state.email = action.payload;
			state.isCodeSend = false;
			codeSendService.saveItem(false);
			activationRequesterService.saveItem(action.payload);
		},
		onClearActivationData : () => {
			activationRequesterService.removeItem();
			codeSendService.removeItem();
		}
	},
	selectors :{
		codeSendSelector : (state) => state.isCodeSend,
		activationRequesterSelector : state => state.email,
	}
});

export const {
  onCodeSendChanged,
  onActivationRequesterChanged,
  onClearActivationData
}= activationSlice.actions;

export const {
  codeSendSelector,
  activationRequesterSelector
}= activationSlice.selectors;

export default activationSlice.reducer;