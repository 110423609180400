import React, {useCallback, useRef, useState}   from "react";
import {Navigate, useNavigate} from "react-router-dom";
import ToggleVisibility from "../../../utils/ToggleVisability";
import {alertStates, useAlert} from "../../../states/useAlert";
import {Box} from "@mui/material";
import {activationRequesterSelector, codeSendSelector, onClearActivationData, onCodeSendChanged} from "../../../features/activation/activationSlice";
import {FormInput, formInputProps, AlertChoise, SubmitCancelForm, Timer} from '../../../Components';
import {useCheckActivationCodeMutation, userApi} from "../../../api/userApi";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import "./AccountActivationForm.css";
const timerSessionItemName = "accountActivationTimerValue";
const timerStartValue : [number, number] = [2,30];

const AccountActivationForm = ()=> {
	const isCodeSend = useAppSelector(codeSendSelector);
	const email = useAppSelector(activationRequesterSelector);
	const dispatch = useAppDispatch();
	const codeSendChanged = useCallback((status : boolean) => dispatch(onCodeSendChanged(status)),[dispatch]);
	const clearActivationData = () => dispatch(onClearActivationData());
	const [againCodeStatus, setAgainCodeStatus] = useState({isRequested : false, labelText : "Нажмите сюда для отправки нового кода на электронную почту"}); 
	const [userInputCode, setActivationCode] = useState("");
	const [over, setOver] = useState(false);
	const navigate = useNavigate();
	const timer = useRef(undefined as NodeJS.Timeout | undefined);
	const [triggerQueryActivationCode, codeQueryResult] = userApi.useLazyQueryActivationCodeQuery();
	const [checkCode, checkCodeResult] = useCheckActivationCodeMutation();
	const {alertStatus, setAlertStatus} = useAlert();

	React.useEffect (()=>{
		if(isCodeSend === false){
			sessionStorage.removeItem(timerSessionItemName);
			codeSendChanged(true);
			triggerQueryActivationCode({email}).unwrap().then(message =>{
				setAgainCodeStatus({isRequested : false, labelText :  "Нажмите сюда для отправки нового кода на электронную почту"});
				setOver(false);
				setAlertStatus(true, alertStates.successAlert, message);
			})
			.catch(error =>{
				console.log(error);
				const {status, message} =error;
				if ( status === 403){
					setAgainCodeStatus({isRequested : false, labelText :  "Нажмите сюда для отправки нового кода на электронную почту"});
				}
				setAlertStatus(true, alertStates.errorAlert,message);
				timer.current = setTimeout(()=>codeSendChanged(true), 3000);
			});
		}
	},[codeSendChanged, isCodeSend, email, setAlertStatus, triggerQueryActivationCode]);
	const handleChange = (e : React.ChangeEvent<HTMLInputElement>)=>{
		setActivationCode(e.target.value);
	};
    
	const sendCode = ()=>{
		if(!againCodeStatus.isRequested){
			codeSendChanged(false);
			setAgainCodeStatus({isRequested : !againCodeStatus.isRequested, labelText : "Ожидание повторной отправки кода"});
		}
	};
    
	const submitForm = (e : React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		checkCode({ email, userInputCode}).unwrap()
		.then(()=>{
			navigate("/registrationSuccess", {replace : true});
			clearActivationData();
		})
		.catch(error =>{
			const { status, message } = error;
			setAlertStatus(true, alertStates.errorAlert, message);
			if(status !== 403)
				console.log(error);
		});
	};
    
	const cancelForm = (e : React.FormEvent<HTMLFormElement>)=>{
		e.preventDefault();
		setOver(true);
		navigate("/", {replace : true});
		onClearActivationData();
		clearTimeout(timer.current);
	};

	if(email){
		return (
			<form 
				id = "accountActivationForm"
				onSubmit={submitForm} 
				onReset={e=>cancelForm}  
				>
				<div className="row">
					<div className="col-md-12 text-center">
						<h1 className='main-reg-text'>
							Активация аккаунта
						</h1>
						<p className='accountActivationText'> 
							Вам на почту отправлено письмо с кодом активации. Для потверждения регистрации на сайте введите код из письма
						</p>
					</div>
				</div>
				<Box sx={{
						width:"50%", 
						margin:"auto"
					}}
					>
					<FormInput 	
						label={"Код активации:"}
						inputProps={
							formInputProps({
								type : "text",
								name : "activationCode",
								onChange : handleChange,
								value : userInputCode,
								minLength : 8,
								maxLength : 8,								
								placeholder : ""
							})
						}
						/>		
				</Box>
				<ToggleVisibility visible={!over}>
					<div className="row">            
						<div className='sendCodeAgainText'>
                            Код не пришёл? Запросите повторный код через:
							<strong> 
								<Timer 	
									startValue={timerStartValue}  
									whenOver={()=>(setOver(true))} 
									sessionItemName={timerSessionItemName}
									/>
							</strong>
						</div>
					</div>
				</ToggleVisibility>
				<ToggleVisibility visible={over}>
					<div className="row">
						<button 
							className='sendCodeAgainButton' 
							type= 'button' 
							onClick={sendCode}
							>
							{againCodeStatus.labelText}
						</button>
					</div>
				</ToggleVisibility>
				<Box sx={{
						width : "50%", 
						margin : "auto",
						marginTop : "0.5rem"
					}}>
					<AlertChoise {...alertStatus}/>
				</Box>
				<SubmitCancelForm 
					resetText='Отмена активации' 
					submitText='Отправить код'
					submitLoading = {codeQueryResult.isFetching || checkCodeResult.isLoading}
					submitLoadingText={ codeQueryResult.isFetching ? 'Отправка кода...' : 'Проверка кода...'}
					/>
			</form>
		);
	}else{
		return(
			<Navigate to="/notFound" replace={true} />
		);
	}
};

export default AccountActivationForm;