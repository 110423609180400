import styled from "styled-components";
import { Typography } from "@mui/material";

export const ContractParagraph = styled(Typography)`
  font-size: larger !important;
  text-indent: 4%;
  text-align: justify !important;
`;

const config = {
  env: {
    apiBase:
      process.env.NODE_ENV === "production"
        ? "/api"
        : "http://192.168.1.100:3500/api",
    staticBase:
      process.env.NODE_ENV === "production"
        ? "/static"
        : "http://192.168.1.100:3500/static",
  },
  publicContract: {
    dateFrom : '28.03.2025',
    number : '209'
  },
  againQueryTimeout: 3000,
  tooltips: {
    productTooltip: (
      <span>
        Билеты на одну поездку на городские перевозки пассажиров в регулярном
        сообщении.
        <br />
        Проездные документы на городские перевозки пассажиров в регулярном
        сообщении, на городские перевозки пассажиров в регулярном сообщении при
        условии совмещения таких перевозок с перевозкой пассажиров поездами
        городских линий, на перевозки пассажиров поездами городских линий на
        бесконтактных смарт-картах.
        <br />
        Пакеты транспортных тарифных единиц на
        перевозки пассажиров поездами городских линий на бесконтактных
        смарт-картах.
        <br />
        При приобретении проездного документа на городские перевозки пассажиров
        в регулярном сообщении, на городские перевозки пассажиров в регулярном
        сообщении при условии совмещения таких перевозок с перевозкой пассажиров
        поездами городских линий, на перевозки пассажиров поездами городских
        линий, пакета транспортных тарифных единиц на
        перевозки пассажиров поездами городских линий на бесконтактной
        смарт-карте Покупатель оплачивает также залоговую стоимость такой карты
        (при ее отсутствии у Покупателя).
      </span>
    ),
    legalEntityTooltip: (
      <span>
        или филиала, представительства или иного обособленное подразделение
        юридического лица Республики Беларусь, которое в соответствии с учетной
        политикой этого юридического лица осуществляет ведение бухгалтерского
        учета с определением финансового результата по его деятельности и
        которому для совершения операций юридическим лицом открыт счет с
        предоставлением права распоряжаться денежными средствами на счете
        должностным лицам этого обособленного подразделения.
      </span>
    ),
  },

  requisites: {
    element: (<>
      <ContractParagraph>
         Филиал «Агентство Минсктранс» коммунального транспортного унитарного предприятия «Минсктранс», просп. Партизанский, 6, 220033, г. Минск.
      </ContractParagraph>
      <ContractParagraph>Электронный адрес: agentstvo@minsktrans.by.</ContractParagraph>
      <ContractParagraph>УНП 102299366, ОКПО 376289405017.</ContractParagraph>
      <ContractParagraph>Расчетный счет BY66BLBB30120102299366001001 в ЦБУ № 535 ОАО «Белинвестбанк» г. Минска, БИК BLBBBY2X.</ContractParagraph>
      <ContractParagraph>
        Контактные телефоны: (+375 17) 246-98-45, (+375 17) 246-98-11, (+375 17) 246-98-48.
      </ContractParagraph>
      </>
    ),
    forCopying: `Коммунальное транспортное унитарное предприятие «Минсктранс» (Государственное предприятие «Минсктранс»). Филиал «Агентство Минсктранс» коммунального транспортного унитарного предприятия «Минсктранс», просп. Партизанский, 6, 220033, г. Минск. Электронный адрес: agentstvo@minsktrans.by. УНП 102299366, ОКПО 376289405017. Расчетный счет BY66BLBB30120102299366001001 в ЦБУ № 535 ОАО «Белинвестбанк» г. Минска, БИК BLBBBY2X. Контактные телефоны: (+375 17) 246-98-45, (+375 17) 246-98-11, (+375 17) 246-98-48.`,
  },
  dateFormats: {
    accountantInfoTable: "LLL",
    accountantUserTable: "LL",
  },
};
export default config;
