import React, { PropsWithChildren } from "react";

type TextLinkProps = PropsWithChildren<{ href?: string }>;

const TextLink = ({ children, href = "/" }: TextLinkProps) => {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

const PaymentLink = (props: TextLinkProps) => (
  <TextLink {...props} href="https://minsktrans.by/oplata-proezda/tarify/" />
);
const ContractPageLink = (props: TextLinkProps) => (
  <TextLink
    {...props}
    href="https://minsktrans.by/filial/filial-agentstvo-minsktrans/#filial_yuridicheskih"
  />
);

const contractText = {
  sections: [
    {
      header: "1. ПРЕДМЕТ ДОГОВОРА",
      paragraphs: [
        <>
          1.1. Продавец продает Покупателю билеты на одну поездку
          на городские перевозки пассажиров в регулярном сообщении; проездные
          документы на городские перевозки пассажиров в регулярном сообщении,
          на городские перевозки пассажиров в регулярном сообщении при условии
          совмещения таких перевозок с перевозкой пассажиров поездами
          городских линий, на перевозки пассажиров поездами городских линий
          на бесконтактных смарт-картах; пакеты транспортных тарифных единиц
          на перевозки пассажиров поездами городских линий на бесконтактных
          смарт-картах (далее, если не указано иное, – товары) по тарифам,
          регулируемым в соответствии с законодательством о ценообразовании
          и опубликованным (размещенным) на{" "}
          <PaymentLink>сайте Продавца</PaymentLink>, а Покупатель производит
          оплату за товары в порядке и на условиях, определенных
          законодательством Республики Беларусь и настоящим договором.
        </>,
        <>
          При приобретении проездного документа на городские перевозки
          пассажиров в регулярном сообщении, на городские перевозки пассажиров
          в регулярном сообщении при условии совмещения таких перевозок с
          перевозкой пассажиров поездами городских линий, на перевозки
          пассажиров поездами городских линий, пакета транспортных тарифных
          единиц на перевозки пассажиров поездами городских линий
          на бесконтактной смарт-карте Покупатель оплачивает также залоговую
          стоимость такой карты (при ее отсутствии у Покупателя).
        </>,
        <>Товары обмену и возврату не подлежат.</>,
        <>1.2. Цель приобретения товаров – для собственного потребления.</>,
      ],
    },
    {
      header: "2. ПОРЯДОК ЗАКЛЮЧЕНИЯ ДОГОВОРА",
      paragraphs: [
        <>
          2.1. Настоящий договор является публичным, в соответствии с
          договором Продавец принимает на себя обязательства по продаже товаров
          в отношении каждого Покупателя, который обратился за их приобретением.
        </>,
        <>
          Публикация (размещение) текста настоящего договора в вебприложении «Оформить заявку» (раздел «Публичный договор») на{" "}
          <ContractPageLink>сайте Продавца</ContractPageLink> является публичным предложением
          (офертой) Продавца, адресованным Покупателю заключить договор.
        </>,
        <>
          2.2. Заключение договора производится путем присоединения
          Покупателя к договору посредством принятия условий договора в целом,
          без каких-либо условий, изъятий и оговорок.
        </>,
        <>
          2.3. Фактом принятия Покупателем условий договора является оплата
          Покупателем товаров в порядке и на условиях, определенных договором.
        </>,
        <>
          2.4. Продавец оставляет за собой право вносить изменения в договор
          в любой момент по своему усмотрению. 
        </>,
      ],
    },
    {
      header: "3. ОБЯЗАННОСТИ СТОРОН",
      paragraphs: [
        <>3.1. Продавец обязуется:</>,
        <>
          3.1.1. Продать (передать) товары по заявке Покупателя при условии
          соблюдения Покупателем всех требований настоящего договора.
        </>,
        <>
          3.1.2. Публиковать (размещать) веб-приложение «Оформить заявку» на {" "}
          <ContractPageLink>сайте Продавца</ContractPageLink>.
        </>,
        <>3.2. Покупатель обязуется:</>,
        <>
          3.2.1. В веб-приложении «Оформить заявку», опубликованном
          (размещенном) на <ContractPageLink>сайте Продавца</ContractPageLink>:
        </>,
        <>
          зарегистрироваться, при этом указать правильные реквизиты
          (местонахождение, банковские реквизиты и др.), позволяющие достоверно
          его идентифицировать;
        </>,
        <>
          оформить заявку, при этом самостоятельно, исходя из своих
          интересов, выбрать наименование товаров и их количество, учитывая
          виды, сроки действия, стоимость, сроки реализации, скачать и распечатать
          счет. 
        </>,
        <>
          3.2.2. Произвести оплату в размере, установленном пунктом 4.2
          настоящего договора.
        </>,
        <>
          3.2.3. Приобрести (получить) товары согласно условиям, указанным
          в пункте 5.1 настоящего договора. 
        </>,
        <>
          3.2.4. Самостоятельно отслеживать на{" "}
          <ContractPageLink>сайте Продавца</ContractPageLink> в веб-приложении
          «Оформить заявку» (раздел «Публичный договор») действующую редакцию
          договора и{" "}
          <PaymentLink>
            информацию о видах, сроках действия, стоимости, сроках реализации
            товаров
          </PaymentLink>
          .
        </>,
      ],
    },
    {
      header: "4. ПОРЯДОК РАСЧЕТОВ",
      paragraphs: [
        <>
          4.1. Источник финансирования – собственные средства. Форма
          оплаты – безналичный расчет. Валюта платежа – белорусский рубль.
        </>,
        <>
          4.2. Покупатель производит предоплату на основании выставленного
          счета в размере полной стоимости товаров, залоговой стоимости
          бесконтактных смарт-карт на расчетный счет Продавца. Срок действия
          счета составляет 5 (пять) рабочих дней с даты его оформления. В случаях:
        </>,
        <>
          повышения стоимости товаров, залоговой стоимости бесконтактных
          смарт-карт, отсутствия у Продавца товара по не зависящим от него
          причинам на день их отпуска Покупателю возвращается сумма
          предварительной оплаты в течение 5 (пяти) банковских дней, после
          поступления заявления на имя Продавца тем же способом, каким
          предварительная оплата была перечислена Покупателем;
        </>,
        <>
          снижения стоимости товаров, залоговой стоимости бесконтактных
          смарт-карт на день их отпуска Покупателю возвращается разница
          в стоимости в течение 5 (пяти) банковских дней, после поступления
          заявления на имя Продавца тем же способом, каким предварительная
          оплата была перечислена Покупателем. 
        </>,
        <>
          В случаях, указанных в абзацах втором, третьем части первой
          настоящего пункта, может быть принято иное решение, относительно
          которого по заявлению Покупателя должно быть достигнуто соглашение. 
        </>,
      ],
    },
    {
      header: "5. ПОРЯДОК ОТПУСКА ТОВАРОВ",
      paragraphs: [
        <>
          5.1. Отпуск товаров производится в течение 5 (пяти) рабочих дней со
          дня поступления денежных средств на расчетный счет Продавца, но не
          ранее 13:00 рабочего дня, следующего за днем поступления денежных
          средств на расчетный счет Продавца, в кассах, указанных в вебприложении «Оформить заявку» (раздел «Кассы») на{" "}
          <ContractPageLink>сайте Продавца</ContractPageLink>, Покупателю или лицу, предъявившему оформленную в установленном
          порядке доверенность Покупателя на получение товаров, документ,
          удостоверяющий личность получателя товаров, и счет.
        </>,
        <>
          На отпущенные товары Покупателю оформляется в установленном
          порядке товарная накладная. 
        </>,
        <>
          5.2. Днем исполнения Продавцом обязательств по договору
          считается дата приема товаров Покупателем согласно товарной накладной. 
        </>,
      ],
    },
    {
      header: "6. ОТВЕТСТВЕННОСТЬ СТОРОН И ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ",
      paragraphs: [
        <>
          6.1. За неисполнение или ненадлежащее исполнение обязательств
          по настоящему договору Стороны несут ответственность в соответствии
          с законодательством Республики Беларусь. 
        </>,
        <>
          6.2. Стороны обязуются решать споры, возникающие при исполнении
          настоящего договора, путем проведения переговоров и предъявления
          претензий
        </>,
        <>
          Претензии направляются заказной корреспонденцией с обратным
          уведомлением или вручаются под подпись. Сторона, к которой была
          предъявлена претензия, должна дать аргументированный ответ
          в письменном виде в срок не позднее 30 (тридцати) календарных дней
          с момента её предъявления. Если Стороны не смогут прийти к соглашению
          по спорным вопросам, любая из Сторон вправе обратиться
          в экономический суд города Минска в соответствии с законодательством
          Республики Беларусь.
        </>,
      ],
    },
    {
      header: "7. ПРОЧИЕ УСЛОВИЯ",
      paragraphs: [
        <>
          7.1. Во всем остальном, что не урегулировано договором, Стороны
          руководствуются законодательством Республики Беларусь. 
        </>,
      ],
    },
    {
      header: "8. АНТИКОРРУПЦИОННАЯ ОГОВОРКА",
      paragraphs: [
        <>
          8.1. Стороны в рамках исполнения настоящего договора обязуются
          соблюдать требования применимого антикоррупционного
          законодательства Республики Беларусь и не предпринимать никаких
          действий, которые могут нарушить нормы антикоррупционного
          законодательства или стать причиной такого нарушения другой Стороной,
          в том числе не требовать, не получать, не предлагать, не санкционировать,
          не обещать и не совершать незаконные платежи напрямую, через третьих
          лиц или в качестве посредника, включая (но не ограничиваясь) взятки
          в денежной или любой иной форме, каким-либо физическим
          или юридическим лицам, включая (но не ограничиваясь) коммерческим
          организациям, органам власти и самоуправления, государственным
          служащим, частным компаниям и их представителям. В случае нарушения
          одной из Сторон изложенных выше антикоррупционных обязательств,
          другая Сторона в праве в одностороннем порядке приостановить
          исполнение своих обязательств по настоящему договору до устранения
          причин такого нарушения или отказаться от исполнения договора,
          направив об этом письменное уведомление.
        </>,
      ],
    },
  ],
};
export default contractText;
