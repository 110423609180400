import "./Registration.css";
import React, {useState}  from "react";
import {useNavigate} from "react-router-dom";
import {alertStates, useAlert} from "../../../states/useAlert";
import {Col, Row} from "react-bootstrap";
import {Box, Checkbox, FormControlLabel} from "@mui/material";
import {passwordStrength} from 'check-password-strength';
import {ContactsInput, LabelWithTooltip, AlertChoise, FormInput, formInputProps} from "../../../Components";
import {useSignupMutation} from "../../../api/userApi";
import config from "../../../config/config";
import {LoadingButton} from "@mui/lab";
import {onActivationRequesterChanged} from "../../../features/activation/activationSlice";
import { useAppDispatch } from "../../../app/hooks/hooks";
import { RawFormInputProps } from "../../../Components/Inputs/FormInput";
import { PhoneItem } from "../../../Components/Inputs/ContactsInput";
import { SignUpBodyArguments } from "../../../api/queryArgumentsTypes";

const userDataDef = {
	registrationNumber : "",
	email : "",
	password: "",
	passwordRepeat: "",
	customerFullName : "",
	legalAddress : "",
	mailingAddress : "",
	checkingAccount : "",
	bankName : "",
	bankCode : "",
	chiefFullName : "",
	chiefDocument : "",
	phoneNumbers : [] as PhoneItem[],
};

const Registration = ()=> {
	const [signUp, result] = useSignupMutation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [userData, setUserData] = useState(userDataDef);
	const {alertStatus : passwordRepeatAlertStatus, setAlertStatus : setPasswordRepeatAlert} = useAlert();
	const {alertStatus : errorAlertStatus, setAlertStatus: setErrorAlertStatus} = useAlert();
	const {alertStatus : passwordStrengthAlert, setAlertStatus : setPasswordStrengthAlert} = useAlert();
	const legalEntityTooltip = config.tooltips.legalEntityTooltip;
	const setActivationRequesterChanged = (email : string) => dispatch(onActivationRequesterChanged(email));

	const handleChange = (e : React.ChangeEvent<HTMLInputElement>)=>{
		const {name, value} = e.target;
		setUserData({...userData,[name]:value});
		if (name === "password" && passwordStrength(value).value !== 'Strong' && value.length > 0){
			setPasswordStrengthAlert(true, alertStates.errorAlert,
				"Слишком простой пароль. Пароль должен иметь длину не менее 10 символов, содержать хотя бы 1 спецсимвол, большую букву, маленькую букву и цифру. Пароль должен содержать только символы английского алфавита, спецсимволы(@,!,$,%,^ и др.) и цифры.");
		}else if (name === "password"){
			setPasswordStrengthAlert(false);
		}
		if( (name === "password" && value === userData.passwordRepeat) ||
			(name === "passwordRepeat" && value === userData.password) ||
			value === ""){
				setPasswordRepeatAlert( false);
		}else if (  (	
						(name === "password" && value !== userData.passwordRepeat &&  userData.passwordRepeat !== "") ||
						(name === "passwordRepeat" && value !== userData.password &&  userData.password !== "")
					) 
					&& value!==""){
				setPasswordRepeatAlert(true, alertStates.passRepeatAlert, "Введенные пароли не совпадают!");
		}		
	};
	const registrationInputProps = ({type, name, value, pattern, minLength, maxLength, placeholder,readOnly, disabled, required} : RawFormInputProps)=> 
			formInputProps({type, name, onChange:handleChange,value, pattern, minLength, maxLength, placeholder, readOnly, disabled, required});

	const resetForm = ()=>{
		setUserData({...userDataDef});
	};

	const submitForm = (e : React.FormEvent<HTMLFormElement>)=>{
		e.preventDefault();
		if(userData.password !== userData.passwordRepeat){
			setErrorAlertStatus(true, alertStates.errorAlert, "Ошибка! Пароли не совпадают");
			return;
		}
		if(passwordStrength(userData.password).value !== 'Strong'){
			setErrorAlertStatus(false);
			return;
		}
		if(userData.phoneNumbers.length === 0){
			setErrorAlertStatus(true, alertStates.errorAlert, "Для регистрации укажите хотя бы один номер телефона");
			return;
		}
		const { passwordRepeat: _,...userSendData } = userData;
		const sendData : SignUpBodyArguments = {
			...(userSendData),
			phoneNumbers : userData.phoneNumbers.map(item =>item.phone)
		};
		signUp(sendData).unwrap().then(()=>{
			setActivationRequesterChanged(userData.email);
			resetForm();
			navigate("/accountActivation");
		})
		.catch(err=> {
			console.log(err);
			const {message} = err;
			setErrorAlertStatus(true, alertStates.errorAlert, message);
		});
	};

	return (
		<div className= "reg_form_contaner">                
			<form 
				id = "registration-form" 
				onSubmit={submitForm}
				>
				<div className="text-center">
					<h1 className='main-reg-text'>
						Регистрация Покупателя
					</h1>
					<p className='registration_text'> 
						Для регистрации укажите правильные реквизиты
					</p>
				</div>
				
				<FormInput 	
					label={(
						<span>
							Наименование{' '}
							<LabelWithTooltip 
								tooltipLabel={legalEntityTooltip} 
								labelText="юридического лица"/>
							<br/> 
							(фамилия, собственное имя, отчество (если таковое имеется) индивидуального предпринимателя)
						</span>
					)} 
					inputProps = { 
						registrationInputProps({
							type : "text",
							name : "customerFullName",
							value : userData.customerFullName,
							placeholder : 'ОАО "Рога и копыта"',
							minLength : 2
						})
					}
					/>
				<FormInput 	
					label={"Фамилия, собственное имя, отчество (если таковое имеется) руководителя"} 
					inputProps = { 
						registrationInputProps({
							type : "text",
							name : "chiefFullName",
							value : userData.chiefFullName,
							placeholder : "Иванов Иван Иванович",
							minLength : 3
						})
						}
						/>
				<FormInput 	
					label={"Наименование документ, на основании которого действует руководитель"} 
					inputProps = { 
							registrationInputProps({
								type : "text",
								name : "chiefDocument",
								value : userData.chiefDocument,
								placeholder : "Устав, доверенность, др",
								minLength : 3
							})
						}
						/>
				<FormInput 	
					label = {(<span>Местонахождение <LabelWithTooltip tooltipLabel={legalEntityTooltip} labelText="юридического лица"/><br/>(место жительства индивидуального предпринимателя)</span>)} 
					inputProps = { 
						registrationInputProps({
							type : "text",
							name : "legalAddress",
							value : userData.legalAddress,
							placeholder : "Ул.Красная, 00-000, 220000, г.Минск",
							minLength : 3
						})
					}
					/>
				<FormInput 	
					label={"Почтовый адрес (адрес для переписки)"} 
					inputProps = { 
						registrationInputProps({
							type : "text",
							name : "mailingAddress",
							value : userData.mailingAddress,
							placeholder : "Ул.Красная, 00-000, 220000, г.Минск",
							minLength : 3
						})
					}
					/>
				<FormInput 	
					label={"Электронный адрес"} 
					inputProps = { 
						registrationInputProps({
							type : "email",
							name : "email",
							value : userData.email,
							placeholder : "example@yandex.by"
						})
					}
					/>
				<FormInput 	
					label={"УНП"} 
					inputProps = { 
						registrationInputProps({
							type : "text",
							name : "registrationNumber",
							value : userData.registrationNumber,
							pattern : "[\\d]{8}[\\d]",
							minLength : 9,
							maxLength : 9,
							placeholder : "100000000"
						})
					}
					/>
				<FormInput 	
					label = {"Расчетный счёт"} 
					inputProps={ 
						registrationInputProps({
							type : "text",
							name : "checkingAccount",
							value : userData.checkingAccount,
							pattern : "BY\\d{2}[A-Z]{4}[\\dA-Z]{20}",
							minLength : 28,
							maxLength : 28,
							placeholder : "BY00AKBB00000000000000000000"
						})
					}
					/>
				<FormInput 	
					label={"Банк"} 
					inputProps = { 
						registrationInputProps({
							type : "text",
							name : "bankName",
							value : userData.bankName,
							placeholder : "ОАО \"АСБ Беларусбанк\", г.Минск",
							minLength : 3
						})
					}
					/>
				<FormInput 	
					label={"БИК"} 
					inputProps = { 
						registrationInputProps({
							type : "text",
							name : "bankCode",
							value : userData.bankCode,
							placeholder : "AKBBBY2X",
							minLength : 8,
							maxLength : 11,
							pattern:"[A-Z]{6}[A-Z0-9]{2}([\\d]{3})?",
						})
					}
					/>
				<ContactsInput 	
					label={"Контактные телефоны"}
					phoneNumbers = {userData.phoneNumbers}
					setPhoneNumbers = {(numbers)=>setUserData({...userData, phoneNumbers : numbers})}
					/>
				
				<FormInput 	
					label={"Пароль"} 
					inputProps={ 
						registrationInputProps({
							type : "password",
							name : "password",
							value : userData.password,
							placeholder : "********",
							minLength : 10,
							autoComplete : 'new-password',
						})
					}
          showOpenPasswordButton
					/>
				<FormInput 	
					label={"Повторите пароль"} 
					inputProps={ 
						registrationInputProps({
							type : "password",
							name : "passwordRepeat",
							value : userData.passwordRepeat,
							placeholder : "********",
							minLength : 10,
							autoComplete : 'new-password',
						})
					}
					/>
				<Box sx={{margin:"0.5rem 0rem"}}>
					<AlertChoise {...passwordStrengthAlert}/>				
				</Box>
				<AlertChoise {...passwordRepeatAlertStatus}/>
				<FormControlLabel  	
					control={
						<Checkbox 
							onInvalid={ (e) => (e.target as EventTarget & HTMLInputElement).setCustomValidity("Для регистрации подтвердите ваше согласие с договором")}
							onChange={ (e) => e.target.setCustomValidity("")}
							required
							/>
						}
					label = {"Покупатель подтверждает, что он ознакомился с Публичным договором на продажу проездных документов по безналичному расчёту, понимает и принимает его."}
					/>
				<Box sx={{marginTop:"1rem"}}>
					<AlertChoise {...errorAlertStatus}/>
				</Box>
				<Row>
					<Col 	
						className="text-center" 
						md = {12}
						>
						<LoadingButton  
							type ="submit" 
							className=" submitReg" 
							variant="contained" 
							color="success"
							loading ={result.isLoading}
							>
							Зарегистрироваться
						</LoadingButton>
					</Col>
				</Row>
			</form>
		</div>
	);
};
export default Registration;