import './ExtractPage.css';
import React, {useEffect, useState} from "react";
import {Box, Button, Typography, Snackbar, Alert, SnackbarCloseReason, AlertColor} from "@mui/material";
import {Download as DownloadIcon, NavigateNext} from "@mui/icons-material";
import {DataGrid, ruRU, GridRowSelectionModel, GridColDef} from "@mui/x-data-grid";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {ruRU as pickersBgBG} from "@mui/x-date-pickers/locales";
import {ruRU as coreBgBG} from "@mui/material/locale";
import config from "../../../config/config";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {userApi} from "../../../api";
import {LoadingBackdrop} from "../../../Components";
import {LoadingButton} from "@mui/lab";
import { UserListItem } from "../../../app/types/userTypes";

type UserStateItem  = UserListItem & {
	id : number,
	phoneNumbersLabel : string,
  email: string,
};

const limit = 200;

const ExtractPage = ()=> {
	const [getLastRegisteredUsersList, lastRegResult] = userApi.useLazyGetLastRegisteredUsersListQuery();
	const [dowloadExtract, downloadResult] = userApi.useLazyDownloadExtractQuery();
	const [rowSelectionModel, setRowSelectionModel] = React.useState([] as GridRowSelectionModel);
	const [users, setUsers] = useState([] as UserStateItem[]);
	const [open, setOpen] = React.useState(false);
	const [snackbar, setSnackbar] = useState({type : 'success', text : ''});
	const navigate = useNavigate();
	
	useEffect(()=>{
		getLastRegisteredUsersList(limit).unwrap()
		.then(usersBasic =>{
			const users = usersBasic.map( user =>({
				...user,
				id : user.userId,
				registrationDate : moment(user.registrationDate).format(config.dateFormats.accountantUserTable),
				phoneNumbersLabel : user.phoneNumbers.reduce((prev, number)=>prev+=number + ', ', ''),
        email: user.email
			}));
			setUsers(users);
		})
		.catch(error =>{
			console.log(error);
			const {message}   = error;
			setSnackbar({type : 'error',text : message});
			setOpen(true);
		});
	},[getLastRegisteredUsersList]);

	const handleClose = (event : Event | React.SyntheticEvent<any, Event>, reason : SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};	
	const getExtract = ()=>{
		dowloadExtract({}).unwrap()
		.catch(error=>{
			console.log(error);
			setSnackbar({type : 'error', text: error?.status + " " + error?.message});
			setOpen(true);
		});
	};
	
	const columns : GridColDef[] = [
		{field: "userId", headerName: "ID", flex : 0.05},
		{field: "customerFullName", headerName: "Наименование юр.лица", flex:0.25},
		{field: "legalAddress", headerName: "Юридический адрес", flex:0.15},
		{field: "registrationNumber", headerName: "УНП", flex:0.10, type : 'number', align : "center", headerAlign : "center"},
		{field: "checkingAccount", headerName: "Расчетный счёт", flex:0.2, align : "center", headerAlign : "center"},
		{field: "phoneNumbersLabel", headerName: "Контактные телефоны", flex:0.15},
		{field: "registrationDate", headerName: "Дата регистрации", flex:0.13},
	];

	const theme = createTheme(
		{
			palette: {
				primary: {main: "#1976d2"},
			},
		},
		ruRU, // x-data-grid translations
		pickersBgBG, // x-date-pickers translations
		coreBgBG, // core translations
	);

	return (
		<>
			<Typography 
				variant="h4" 
				align="center"
				>
				Реестр пользователей:
			</Typography>
			<Typography 
				variant="h6" 
				align="center" 
				sx={{marginBottom:"3vh"}}
				>
				В таблице представлены последние {limit} зарегистрировавшихся пользователей, для получения полного списка скачайте список в формате Excel
			</Typography>
			<Box 
				sx = {{
					width:"100%", 
					margin:"auto", 
					marginBottom:'3vh'
				}}>
				<ThemeProvider theme={theme}>
					<DataGrid
						autoHeight 
						rows = {users} 
						columns={columns} 
						rowSelectionModel={rowSelectionModel}
						onRowSelectionModelChange={setRowSelectionModel}
						initialState={{
							pagination: {paginationModel: {pageSize: 10}},
						}}
						pageSizeOptions={[10,25,50]}
						getRowHeight={() => 'auto'} 
						/>
				</ThemeProvider>
			</Box>
			
				<Box 
					textAlign={'center'} 
					justifyContent={'space-evenly'} 
					display={'flex'}
					>
					<LoadingButton 
						className='printButton'
						type="button"
						variant="contained"
						endIcon = {<DownloadIcon/>}
						loadingPosition="end"
						onClick = {getExtract}
						loading = {downloadResult.isFetching }
						>
						{downloadResult.isFetching ?  "Идет скачивание..." : "Скачать реестр"}
					</LoadingButton>
					<Button 
						className='printButton'
						type="button"
						variant="contained"
						endIcon = {<NavigateNext/>}
						onClick={()=> {
							if(rowSelectionModel.length === 0){
								setSnackbar({type:'error', text : "Пользователь не выбран. Выберите пользователя в таблице"});
								setOpen(true);
								return;
							}
							const user = users.find( user => user.userId === rowSelectionModel[0] );
							if (user){
								const {email} = user;
								navigate('/userInfo/', {state:{userEmail : email}});
							}
						}}>
						Просмотреть данные пользователя
					</Button>
				</Box>
			
			<Snackbar 
				open={open} 
				autoHideDuration={5000} 
				onClose={handleClose}
				>
				<Alert 
					severity={snackbar.type as AlertColor} 
					sx={{width: '100%'}}
					>
					{snackbar.text}
				</Alert>
			</Snackbar>
			<LoadingBackdrop open={lastRegResult.isFetching}/>
		</>
	);
};
export default ExtractPage;
