import "./App.css";
import React  from "react";
import {Route, BrowserRouter  as Router, Routes} from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./routes/common/Home";
import Registration from "./routes/unauthorized/Registration";
import Login from "./routes/unauthorized/Login";
import UserCabinet from "./routes/customer/UserCabinet";
import ContractForm from "./routes/customer/ContractForm";
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import ContractsList from "./routes/customer/ContractsList";
import AccountActivationForm from "./routes/unauthorized/AccountActivation";
import RegistrationSuccessPage from "./routes/unauthorized/RegistrationSuccessPage";
import NotFoundPage from "./routes/common/NotFoundPage";
import PasswordChangeCodeInputForm from "./routes/unauthorized/PasswordChangeInputForm";
import PasswordChangeForm from "./routes/unauthorized/PasswordChangeForm";
import ContractAddedPage from "./routes/customer/ContractAddedPage";
import AuthenticationGuard from "./utils/AuthenticationGuard";
import AuthExpireModal from "./Components/Modals/AuthExpireModal";
import {roleNames} from "./config/roleNames";
import TravelDocumentCreateForm from "./routes/moderator/TravelDocumentCreateForm";
import TravelDocumentList from "./routes/moderator/TravelDocumentList";
import TicketWindowList from "./routes/customer/TicketWindowList";
import UserInfoPage from "./routes/accountant/UserInfoPage";
import InvoiceSearchPage from "./routes/accountant/InvoiceSearchPage";
import UsersAdminPanel from "./routes/admin/UserStatusPage";
import TravelDocumentEditPage from "./routes/moderator/TravelDocumentEditPage";
import ExtractPage from "./routes/accountant/ExtractPage";
import { CssBaseline } from "@mui/material";

function App() : React.ReactElement{
	return (
		<Router>
			<CssBaseline/>
			<Header/>
			<div className="container appContainer">
				<Routes>
					<Route path ="/" element = {<Home/>}></Route>
          <Route path ="/ticketWindowList" element = {<TicketWindowList/>}></Route> 
					<Route element = {<AuthenticationGuard redirectPath = {'/'} guardType = "unauthenticated" acceptedRoles = {[roleNames.unauthorized]} />}>
						<Route path ="/registration" element = {<Registration/>}></Route>
						<Route path ="/login"  element = {<Login/>}></Route>
						<Route path ="/accountActivation"  element = {<AccountActivationForm/>}></Route>
						<Route path ="/registrationSuccess"  element = {<RegistrationSuccessPage/>}></Route>
						<Route path ="/passwordChangeCodeInput"  element = {<PasswordChangeCodeInputForm/>}></Route>
						<Route path ="/passwordChangeForm"  element = {<PasswordChangeForm/>}></Route>
					</Route>
					<Route element = {<AuthenticationGuard redirectPath = {'/'} guardType = "authenticated" acceptedRoles = {[roleNames.customer]}  />}>
						<Route path ="/personalAccount" element = {<UserCabinet/>}></Route>
						<Route path ="/invoiceForm" element = {<ContractForm/>}></Route> 
						<Route path ="/contractAdded" element = {<ContractAddedPage/>}></Route> 
						{/* <Route path ="/invoiceList" element = {<ContractsList/>}></Route>  */}
					</Route>

					<Route element = {<AuthenticationGuard redirectPath = {'/'} guardType = "authenticated" acceptedRoles = {[roleNames.accountant]}  />}>
						<Route path ="/userInfo/" element = {<UserInfoPage/>}></Route>
						<Route path ="/search/invoice/" element = {<InvoiceSearchPage/>}></Route>
						<Route path ="/userInfo/extract/" element = {<ExtractPage/>}></Route>
					</Route>

					<Route element = {<AuthenticationGuard redirectPath = {'/'} guardType = "authenticated" acceptedRoles = {[roleNames.moderator, roleNames.admin]}  />}>
						<Route path ="/createTravelDocument" element = {<TravelDocumentCreateForm/>}></Route>
						<Route path ="/travelDocumentList" element = {<TravelDocumentList/>}></Route> 
						<Route path ="/travelDocument/edit/" element = {<TravelDocumentEditPage/>}></Route> 
					</Route>

					<Route element = {<AuthenticationGuard redirectPath = {'/'} guardType = "authenticated" acceptedRoles = {[roleNames.admin]}  />}>
						<Route path ="/user/status/" element = {<UsersAdminPanel/>}></Route>
					</Route>
					<Route path ="/notFound"  element = {<NotFoundPage/>}></Route>
					<Route path ="*"  element = {<NotFoundPage/>}></Route>
				</Routes>
			</div>
			<Footer/>
			<AuthExpireModal/>
		</Router>
	);
}
export default App;
